import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';
import SolutionsBlockES from '../components/solutionsblock.component.es';
import MicroBenefit from '../components/microbenefit.component';
import BottomCTA from '../components/bottomCTA.component.es';

import '../styles/product.scss';
import introProductImage from '../static/images/KuFlow-Organizaciones-Digitales.gif';

export default function Product() {
	return (
		<LayoutES>
			<Seo title='Producto' />
			<section id='introProduct'>
				<div className='introProduct-columns'>
					<div id='introProduct-text' class='introProduct-column'>
						<h1>Eeeesto era lo de digitalizar organizaciones</h1>
						<p>
							KuFlow es un director de orquesta virtual que con su batuta se
							encargará de{' '}
							<strong>
								gestionar personas, bots, tareas, archivos y procesos
							</strong>{' '}
							para que las cosas funcionen casi solas.
						</p>
						<p>
							Como “conducir” un coche autónomo, pero sin posibilidad de chocar
							contra un camión.
						</p>
					</div>
					<div id='introProduct-image' class='introProduct-column'>
						<img src={introProductImage} alt='Trabajo cómodo con KuFlow' />
					</div>
				</div>
			</section>
			<section id='explanation'>
				<h2 style={{ marginBottom: '1rem' }}>KuFlow explicado en 2 minutos</h2>
				<iframe
					id='youtube_player'
					className='modal-content-video-inner'
					width='960'
					height='540'
					src='https://www.youtube.com/embed/O34TjsXuGUw'
					title='YouTube video player'
					frameborder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowfullscreen
				></iframe>
				<div class='benefits'>
					<MicroBenefit imageUrl='/images/kuflow_orquestacion_tareas.jpg'>
						<h3>Orquestación de tareas</h3>
						<p>
							KuFlow es una <strong>plataforma developer-friendly</strong> con
							el que se pueden definir procesos con código. Gracias a ello, se
							puede <strong>programar todo lo que se te ocurra</strong>, por
							ejemplo:
						</p>
						<ul>
							<li>
								Quién tiene que hacer qué (con un potente sistema de permisos).
							</li>
							<li>Qué documentos hay que adjuntar y almacenarlos.</li>
							<li>
								Qué decisiones se tienen que tomar, sean humanas o automáticas.
							</li>
							<li>
								Qué sucede cuando alguna tarea o proceso cambia de estado.
							</li>
						</ul>
					</MicroBenefit>
					<MicroBenefit imageUrl='/images/kuflow_interacción_humano-maquina.jpg'>
						<h3>Interacción humano-máquina</h3>
						<p>
							KuFlow será tu <strong>asistente virtual</strong>, recordándote
							que tienes una tarea pendiente, que hay que subir un documento o
							que en algún proceso una decisión depende de ti.
						</p>
						<p>
							KuFlow quiere enfocarte en el trabajo, dándote una mesa virtual en
							la que no tengas que estar con 4 ventanas y 22 pestañas para
							tenerlo todo en orden.
						</p>
					</MicroBenefit>
					<MicroBenefit imageUrl='/images/kuflow_rpa.jpg'>
						<h3>Tareas que se hacen solas</h3>
						<p>
							KuFlow no solo hace listas, sino que también{' '}
							<strong>completa tareas</strong>.
						</p>
						<p>
							Enséñale lo que tiene que hacer una vez y a la segunda ya se
							encarga KuFlow. Ojalá becarios así.
						</p>
					</MicroBenefit>
					<MicroBenefit imageUrl='/images/kuflow_dashboard.jpg'>
						<h3>Todo bajo control</h3>
						<p>
							Con el rol de responsable{' '}
							<strong>
								puedes ver quién está atascado o quién se está escaqueando
							</strong>{' '}
							de un tipo de tareas concretas.
						</p>
						<p>
							{' '}
							Pero no te sientas mal, no es micromanagement si solo lo revisas
							un par de veces a la semana *guiño guiño*.
						</p>
					</MicroBenefit>
				</div>
			</section>
			<SolutionsBlockES />
			<BottomCTA />
		</LayoutES>
	);
}
